import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { FadeIn } from "./animations"

const BlogCategories = () => {
  const result = useStaticQuery(graphql`
    query BlogCategories {
      allSanityCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `)

  const categories = result.allSanityCategory.nodes

  return (
    <FadeIn delay={0.5}>
      <div className=" lg:w-11/12 mx-auto pt-8 overflow-x-scroll no-scrollbar">
        <div className="relative border-y overflow-x-scroll no-scrollbar border-gray-500 w-full">
          {/* <div className="c-edge-fade"></div> */}
          <ul className="py-4 px-4 w-max no-scrollbar flex space-x-10 sm:space-x-16 lg:space-x-20 lg:text-xl xl:text-2xl">
            {categories.map(({ title, slug: { current: slug } }) => (
              <li>
                <Link
                  className="anim-link-effect-1 font-headings"
                  to={`/blog/categories/${slug}`}
                >
                  <span data-hover={title}>{title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </FadeIn>
  )
}

export default BlogCategories
