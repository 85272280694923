import React from "react"
import { Link } from "gatsby"

import { FadeIn } from "./animations"

const Pagination = ({ rootPath, data: { numberOfPages, humanPageNumber } }) => {
  return (
    <FadeIn>
      <div className="lg:w-11/12 mx-auto overflow-x-scroll no-scrollbar">
        <div className="border-y overflow-x-scroll no-scrollbar border-gray-500 w-max mx-auto">
          <ul className="py-4 px-4 no-scrollbar flex space-x-10 lg:space-x-14">
            {Array(numberOfPages)
              .fill(0)
              .map((_, i) => {
                let pageNo = i + 1
                let pageURLIndex =
                  pageNo === 1 ? rootPath : `${rootPath}/${pageNo}`
                return (
                  <li className="font-headings lg:text-xl">
                    <Link
                      to={pageURLIndex}
                      className={
                        pageNo === humanPageNumber
                          ? `text-[#999] cursor-default`
                          : "hover:underline"
                      }
                    >
                      {pageNo}
                    </Link>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </FadeIn>
  )
}

export default Pagination
